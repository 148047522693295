<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
  >
    <b-card>

      <b-row>
        <b-col
          lg="2"
          md="3"
          sm="3"
          class="my-1"
        >
          <!-- BOTON MOSTRAR -->
          <btnMostrar
            :pageOptions.sync="pageOptions"
            :perPage.sync="perPage"
            :total.sync="getUbicaciones.length"
          />
        </b-col>
        <b-col
          lg="6"
          md="5"
          sm="5"
          class="my-1"
        >
          <!-- FILTRO -->
          <inputFiltro
            :filter.sync="filter"
          />
        </b-col>
        <b-col
          md="4"
          sm="4"
          class="my-1"
        >
          <!-- BOTON CREAR -->
          <btnCrear
            texto="Ubicación"
            modulo="ubicaciones"
            @processAdd="addUbicacion"
          />
        </b-col>

        <b-col cols="12">
          <b-table
            striped
            small
            hover
            noCollapse
            class="mt-1"
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="getUbicaciones"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :busy="cargando"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <spinner />
              </div>
            </template>

            <!-- COLUMNA ESTADO -->
            <template #cell(estado)="data">
              <colEstado
                :data="data"
                modulo="ubicaciones"
                @processUpdateEstado="updateEstado"
              />
            </template>

            <!-- Column: Action -->
            <template #cell(acciones)="data">
              <colAccionesBtnes
                modulo="ubicaciones"
                :data="data"
                :estado="data.item.destroy.estado"
                :cantidad="data.item.destroy.cantidad"
                @processGoToUpdate="goToUpdateUbicacion"
                @processRemove="removeUbicacion(data.item)"
              />
            </template>
          </b-table>
        </b-col>

        <b-col
          cols="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BTable, BRow, BCol, BPagination, BOverlay, BCard,
} from 'bootstrap-vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'

const colAccionesBtnes = () => import('@/layouts/components/Recycled/List/colAccionesBtnes.vue')
const btnCrear = () => import('@/layouts/components/Recycled/List/btnCrear.vue')
const inputFiltro = () => import('@/layouts/components/Recycled/List/inputFiltro.vue')
const btnMostrar = () => import('@/layouts/components/Recycled/List/btnMostrar.vue')
const colEstado = () => import('@/layouts/components/Recycled/List/colEstado.vue')
const spinner = () => import('@/layouts/components/Recycled/Form/spinner.vue')

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,
    BOverlay,
    BCard,

    spinner,
    colAccionesBtnes,
    btnCrear,
    inputFiltro,
    btnMostrar,
    colEstado,
  },
  data() {
    return {
      spinner: false,
      cargando: true,
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [3, 5, 10],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'nombre',
          label: 'Nombre',
          sortable: true,
          thStyle: {
            width: 'auto !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'estado',
          label: 'Estado',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            width: '150px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '150px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ getUbicaciones: 'ubicaciones/getUbicaciones' }),
    // Vuexy
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    getUbicaciones(val) {
      this.totalRows = val.length
    },
  },
  mounted() {
    this.fetchUbicaciones().then(() => {
      this.cargando = false
    })
  },
  methods: {
    ...mapActions({
      fetchUbicaciones: 'ubicaciones/fetchUbicaciones',
      updateUbicacionEstado: 'ubicaciones/updateUbicacionEstado',
      removeUbicaciones: 'ubicaciones/removeUbicaciones',
    }),
    ...mapMutations('ubicaciones', ['setUbicacion']),
    addUbicacion() {
      this.$router.replace({
        name: 'ubicaciones-create',
      })
    },
    updateEstado(ubicacion) {
      this.$swal({
        title: 'Cambio de Estado!',
        text: `Estás seguro que deseas cambiar el estado de "${ubicacion.nombre}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, cambialo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.spinner = true
        if (result.value) {
          this.updateUbicacionEstado(ubicacion).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Estado actualizado!',
              text: `Se ha actualizado el estado de "${ubicacion.nombre}"!`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.spinner = false
          })
        } else {
          this.spinner = false
        }
      })
    },
    goToUpdateUbicacion(ubicacion) {
      this.setUbicacion(ubicacion)
      this.$router.push({
        name: 'ubicaciones-update',
        params: { id: ubicacion.id },
      })
    },
    removeUbicacion(ubicacion) {
      this.$swal({
        title: 'Eliminar ubicación!',
        text: `Estás seguro que deseas eliminar la ubicación "${ubicacion.nombre}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminala!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.spinner = true
        if (result.value) {
          this.removeUbicaciones(ubicacion.id).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Eliminada con éxito!',
              text: `"${ubicacion.nombre}" ha sido eliminada!`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.spinner = false
          })
        } else {
          this.spinner = false
        }
      })
    },
    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
